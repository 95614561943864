import MediaQuery from "react-responsive"

import { useIsMounted } from "src/hooks/isMounted"

// TODO: This is a workaround.
// This will hide the content inside Media until the client is available
// Another solution would be to `return <>{props.children}</>` in the first if,
// but this would render responsive layout together
export const Media: typeof MediaQuery = (props) => {
  const isMounted = useIsMounted()

  if (!isMounted) return null

  return <MediaQuery {...props} />
}
