import { GarageNetworkComparisonProps } from "../../cck/elements/garageNetworkComparison/GarageNetworkComparison"
import { ContentfulSection } from "../ContentfulSection"
import { ClickableBannerProps } from "src/components/cck/elements/clickableBanner"
import { ContentfulButtonProps } from "src/components/cck/elements/contentfulButton"
import { CopyTextProps } from "src/components/cck/elements/copyText"
import { EditorialTeaserProps } from "src/components/cck/elements/editorialTeaser"
import { FAQAccordionProps } from "src/components/cck/elements/faqAccordion"
import { FoldingAreaProps } from "src/components/cck/elements/foldingArea"
import { FullFormatImageProps } from "src/components/cck/elements/fullFormatImage"
import { HeadlineProps } from "src/components/cck/elements/headline"
import { ContentfulImageProps } from "src/components/cck/elements/image"
import { ImageTextModuleProps } from "src/components/cck/elements/imageTextModule"
import { InstructionalStepProps } from "src/components/cck/elements/instructionalStep"
import { IntroTextProps } from "src/components/cck/elements/introText"
import { NoteProps } from "src/components/cck/elements/note"
import { ProgressBarProps } from "src/components/cck/elements/progressBar"
import { QuoteTileProps } from "src/components/cck/elements/quoteTile"
import { SalesTeaserProps } from "src/components/cck/elements/salesTeaser"
import { TopBannerProps } from "src/components/cck/elements/topBanner/TopBanner"
import { BenefitBoxProps } from "src/components/cck/sections/benefitBox"
import { BoldTeaserProps } from "src/components/cck/sections/boldTeaser"
import { FooterProps } from "src/components/cck/sections/footer"
import { HearoTeaserProps } from "src/components/cck/sections/heroTeaser"
import { IconListProps } from "src/components/cck/sections/iconList"
import { LogoBandProps } from "src/components/cck/sections/logoBand"
import { NavigationProps } from "src/components/cck/sections/navigation"
import { NumberedStepsProps } from "src/components/cck/sections/numberedSteps"
import { PageIntroProps } from "src/components/cck/sections/pageIntro"
import { SectionModuleProps } from "src/components/cck/sections/sectionModule"
import { ServiceCategoriesProps } from "src/components/cck/sections/serviceCategories"
import { SliderModuleProps } from "src/components/cck/sections/sliderModule"
import { TeaserModuleProps } from "src/components/cck/sections/teaserModule"
import { VideoModuleProps } from "src/components/cck/sections/videoModule"
import { IconName } from "src/components/common/icon"
import { MetaProps } from "src/components/core/meta"
import { ProductPageProps } from "src/components/pages/services/flow/service/types"
import { ContentfulCommonContentType } from "src/types/contentful"
import { ContentfulAssetData } from "src/types/contentful/autoservice"
import {
  ContentfulItemButtonData,
  ContentfulItemCopyTextData,
  ContentfulItemEditorialTeasertData,
  ContentfulItemFoldingAreaData,
  ContentfulItemFullFormatImageData,
  ContentfulItemHeadlineData,
  ContentfulItemImageTextModuleData,
  ContentfulItemInstructionalStepData,
  ContentfulItemIntroTextData,
  ContentfulSectionBenefitBoxData,
  ContentfulSectionBoldTeaserData,
  ContentfulSectionFAQAccordionData,
  ContentfulSectionIconListData,
  ContentfulSectionPageIntroData,
  ContentfulSectionVideoModule,
  ContentfulItemSalesTeaserData,
  ContentfulSectionTeaserData,
  ContentfulSectionSliderModuleData,
  ContentfulSectionHeroTeaserData,
  ContentfulSectionLogoBandData,
  ContentfulSectionModuleData,
  ContentfulPageProductPage,
  ContentfulItemNoteData,
  ContentfulMetaData,
  ClickableBannerData,
  ContentfulItemGarageNetworkComparisonData,
  ContentfulSectionServiceCategoriesData,
  ContentfulItemQuoteTileData,
  ContentfulSectionFooterData,
  ContentfulSectionNavigationData,
  ContentfulItemTopBannerData,
  ContentfulSectionBenefitFieldIcons,
  ContentfulItemProgressBarData,
  ContentfulSectionNumberedStepsData,
  ContentfulSectionHeaderNavigationData,
  ContentfulItemHeaderNavigationData,
  ContentfulSectionStickyFooterData,
} from "src/types/contentful/cck"
import { HeaderNavigationProps } from "src/types/HeaderNavigationProps"

export const cckContentPropsMapping: {
  [key in ContentfulCommonContentType | "itemImage"]?: any
} = {
  itemButton: (data: ContentfulItemButtonData): ContentfulButtonProps => {
    return {
      link: data?.fields?.link ?? "",
      variant: data?.fields?.variant,
      size: data?.fields?.size,
      children: data?.fields?.text,
      tracking: data?.fields?.tracking ?? null,
    }
  },

  itemCopyText: (data: ContentfulItemCopyTextData): CopyTextProps => {
    return {
      id: data?.sys?.id || null,
      content: data?.fields?.content || null,
      alignment: data?.fields?.aligment || null,
    }
  },

  itemEditorialTeaser: (
    data: ContentfulItemEditorialTeasertData,
  ): EditorialTeaserProps => ({
    headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
    subHeadline: cckContentPropsMapping.itemHeadline(data?.fields?.subHeadline),
    headlineHighlight: data?.fields?.headlineHighlight,
    copyText: cckContentPropsMapping.itemCopyText(data?.fields?.copyText),
    theme: data?.fields?.theme,
    size: data?.fields?.size,
    image: cckContentPropsMapping.itemImage(data?.fields?.image),
    icon: data?.fields?.icon?.fields?.iconName,
    link: data?.fields?.link,
    tracking: data?.fields?.tracking ?? null,
  }),

  itemFoldingArea: (data: ContentfulItemFoldingAreaData): FoldingAreaProps => {
    return {
      showLabel: data?.fields?.showLabel ?? "",
      hideLabel: data?.fields?.hideLabel ?? "",
      children: <ContentfulSection data={data?.fields?.content} />,
    }
  },

  itemFullFormatImage: (
    data: ContentfulItemFullFormatImageData,
  ): FullFormatImageProps => {
    return {
      image: cckContentPropsMapping.itemImage(data?.fields?.image),
      description: data?.fields?.description
        ? cckContentPropsMapping.itemCopyText(data?.fields?.description)
        : null,
    }
  },

  itemHeadline: (data: ContentfulItemHeadlineData): HeadlineProps => {
    return {
      id: data?.sys?.id ?? "",
      align: data?.fields?.align,
      level: data?.fields?.level,
      highlighted: data?.fields?.highlighted,
      children: data?.fields?.text,
    }
  },

  itemImage: (data: ContentfulAssetData): ContentfulImageProps => {
    return {
      url: data?.fields?.file?.url,
      title: data?.fields?.title,
      description: data?.fields?.description,
    }
  },

  itemImageTextModule: (
    data: ContentfulItemImageTextModuleData,
  ): ImageTextModuleProps => {
    return {
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      image: cckContentPropsMapping.itemImage(data?.fields?.image),
      imageDescription: cckContentPropsMapping.itemCopyText(
        data?.fields?.imageDescription,
      ),
      imageAlignment: data?.fields?.imageAlignment,
      text: cckContentPropsMapping.itemCopyText(data?.fields?.text),
      iconName: data?.fields?.icon?.fields?.iconName,
      theme: data?.fields?.theme,
    }
  },

  itemInstructionalStep: (
    data: ContentfulItemInstructionalStepData,
  ): InstructionalStepProps => {
    return {
      image: cckContentPropsMapping.itemImage(data?.fields?.image),
      copyText: cckContentPropsMapping.itemCopyText(data?.fields?.copyText),
    }
  },

  itemQuoteTile: (data: ContentfulItemQuoteTileData): QuoteTileProps => {
    return {
      name: data?.fields?.name,
      quote: cckContentPropsMapping.itemCopyText(data?.fields?.quote),
      rating: data?.fields?.rating,
    }
  },

  itemIntroText: (data: ContentfulItemIntroTextData): IntroTextProps => {
    return {
      align: data?.fields?.align,
      children: data?.fields?.text,
    }
  },

  itemGarageNetworkComparison: (
    data: ContentfulItemGarageNetworkComparisonData,
  ): GarageNetworkComparisonProps => {
    return {
      left: {
        title: data?.fields?.leftTitle,
        subtitle: data?.fields?.leftSubtitle,
        content: data?.fields?.leftCopyText?.fields,
        logo: {
          alt: data?.fields?.leftLogo?.fields?.description,
          url: data?.fields?.leftLogo?.fields?.file?.url,
        },
      },
      right: {
        title: data?.fields?.rightTitle,
        subtitle: data?.fields?.rightSubtitle,
        content: data?.fields?.rightCopyText?.fields,
        logo: {
          alt: data?.fields?.rightLogo?.fields?.description,
          url: data?.fields?.rightLogo?.fields?.file?.url,
        },
      },
      bottom: data?.fields?.bottomCopyText?.fields,
    }
  },

  itemNote: (data: ContentfulItemNoteData): NoteProps => {
    return {
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      copyText: cckContentPropsMapping.itemCopyText(data?.fields?.copyText),
      variant: data?.fields?.variant,
    }
  },

  itemSalesTeaser: (data: ContentfulItemSalesTeaserData): SalesTeaserProps => {
    return {
      productImage: cckContentPropsMapping.itemImage(
        data?.fields?.productImage,
      ),
      backgroundImage: cckContentPropsMapping.itemImage(
        data?.fields?.backgroundImage,
      ),
      backgroundColor: data?.fields?.backgroundColor,
      tileTag: data?.fields?.tileTag,
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      copyText: cckContentPropsMapping.itemCopyText(data?.fields?.copyText),
      supplementaryText: data?.fields?.supplementaryText,
      buttonData: cckContentPropsMapping.itemButton(data?.fields?.buttonData),
      theme: data?.fields?.theme,
      size: data?.fields?.size,
      textColor: data?.fields?.textColor,
      disruptorPrice: data?.fields?.disruptorPrice,
      isDisruptorPriceExact: data?.fields?.isDisruptorPriceExact,
      disruptorPriceConditions: data?.fields?.disruptorPriceConditions,
    }
  },

  sectionBenefitsBox: (
    data: ContentfulSectionBenefitBoxData,
  ): BenefitBoxProps => {
    const mapIconsNames = (icons?: ContentfulSectionBenefitFieldIcons) => {
      if (!icons) {
        return
      }
      const iconsNames: IconName[] = []
      icons?.forEach((icon) => {
        if (icon?.fields?.iconName) {
          iconsNames.push(icon?.fields?.iconName)
        }
      })
      return iconsNames
    }

    return {
      theme: data?.fields?.theme,
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      icons: mapIconsNames(data?.fields?.icons),
      button: cckContentPropsMapping.itemButton(data?.fields?.button),
      copyText: cckContentPropsMapping.itemCopyText(data?.fields?.copyText),
    }
  },

  sectionFaqAccordion: (
    data: ContentfulSectionFAQAccordionData,
  ): FAQAccordionProps => {
    return {
      items:
        data?.fields?.content?.map((item) => ({
          headline: cckContentPropsMapping.itemHeadline(item?.fields?.title),
          content: cckContentPropsMapping.itemCopyText(item?.fields?.content),
        })) ?? [],
    }
  },

  sectionHeroTeaser: (
    data: ContentfulSectionHeroTeaserData,
  ): HearoTeaserProps => {
    return {
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      image: cckContentPropsMapping.itemImage(data?.fields?.image),
      link: data?.fields?.link || "/",
      linkText: data?.fields?.linkText || "",
      teaserText: cckContentPropsMapping.itemCopyText(data?.fields?.teaserText),
      subHeadline: cckContentPropsMapping.itemHeadline(
        data?.fields?.subHeadline,
      ),
    }
  },

  sectionIconList: (data: ContentfulSectionIconListData): IconListProps => {
    const items =
      data?.fields?.listItems?.map((item) => {
        const { content, icon, iconColor, title, iconBackground } =
          item?.fields || {}
        return {
          title,
          content,
          icon: icon?.fields?.iconName,
          color: iconColor,
          iconBackground,
        }
      }) || []

    return {
      items,
      defaultIcon: data?.fields?.defaultIcon?.fields?.iconName,
      defaultIconColor: data?.fields?.defaultIconColor,
      defaultIconBackground: data?.fields?.defaultIconBackground,
    }
  },

  sectionModule: (data: ContentfulSectionModuleData): SectionModuleProps => {
    return {
      variant: data?.fields?.variant,
      contentWidth: data?.fields?.contentWidth,
      children: (data?.fields?.sections ?? []).map((section) => (
        <ContentfulSection key={section?.sys?.id} data={section} />
      )),
    }
  },

  sectionPageIntro: (data: ContentfulSectionPageIntroData): PageIntroProps => {
    return {
      image: cckContentPropsMapping.itemImage(data?.fields?.image),
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      button: cckContentPropsMapping.itemButton(data?.fields?.button),
      copyText: cckContentPropsMapping.itemCopyText(data?.fields?.copyText),
    }
  },

  sectionSliderModule: (
    data: ContentfulSectionSliderModuleData,
  ): SliderModuleProps => {
    const tiles = data?.fields?.tiles

    const editorialTeasers = [] as EditorialTeaserProps[]
    const salesTeasers = [] as SalesTeaserProps[]
    const instructionalSteps = [] as InstructionalStepProps[]
    const quoteTiles = [] as QuoteTileProps[]

    tiles?.forEach((teaser) => {
      const teaserId = teaser?.sys?.contentType?.sys?.id

      if (teaserId === "itemEditorialTeaser") {
        editorialTeasers.push(cckContentPropsMapping[teaserId](teaser))
      }

      if (teaserId === "itemSalesTeaser") {
        salesTeasers.push(cckContentPropsMapping[teaserId](teaser))
      }

      if (teaserId === "itemInstructionalStep") {
        instructionalSteps.push(cckContentPropsMapping[teaserId](teaser))
      }

      if (teaserId === "itemQuoteTile") {
        quoteTiles.push(cckContentPropsMapping[teaserId](teaser))
      }
    })

    return {
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      introText: cckContentPropsMapping.itemIntroText(data?.fields?.introText),
      editorialTeasers,
      salesTeasers,
      instructionalSteps,
      quoteTiles,
      hideChevrons: data?.fields?.hideChevrons,
      hasAutoscroll: data?.fields?.hasAutoscroll,
      scrollInterval: data?.fields?.scrollInterval,
    }
  },

  sectionVideoModule: (
    data: ContentfulSectionVideoModule,
  ): VideoModuleProps => {
    const videoProps = {
      title: data?.fields?.video?.fields?.title,
      description: data?.fields?.video?.fields?.description,
      url: data?.fields?.video?.fields?.file?.url,
    }

    return {
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      copyText: cckContentPropsMapping.itemCopyText(data?.fields?.copyText),
      video: videoProps,
      thumb: cckContentPropsMapping.itemImage(data?.fields?.thumb),
      introText: cckContentPropsMapping.itemIntroText(data?.fields?.introText),
    }
  },

  sectionTeaser: (data: ContentfulSectionTeaserData): TeaserModuleProps => {
    const teaserArea = data?.fields?.teaserArea

    const editorialTeasers = [] as EditorialTeaserProps[]
    const salesTeasers = [] as SalesTeaserProps[]
    const instructionalSteps = [] as InstructionalStepProps[]
    const quoteTiles = [] as QuoteTileProps[]
    const boldTeasers = [] as BoldTeaserProps[]

    teaserArea?.forEach((teaser) => {
      const teaserId = teaser?.sys?.contentType?.sys?.id

      if (teaserId === "itemEditorialTeaser") {
        editorialTeasers.push(
          cckContentPropsMapping.itemEditorialTeaser(teaser),
        )
      }

      if (teaserId === "itemSalesTeaser") {
        salesTeasers.push(cckContentPropsMapping.itemSalesTeaser(teaser))
      }

      if (teaserId === "itemInstructionalStep") {
        instructionalSteps.push(
          cckContentPropsMapping.itemInstructionalStep(teaser),
        )
      }

      if (teaserId === "itemQuoteTile") {
        quoteTiles.push(cckContentPropsMapping.itemQuoteTile(teaser))
      }

      if (teaserId === "sectionBoldTeaser") {
        boldTeasers.push(cckContentPropsMapping.sectionBoldTeaser(teaser))
      }
    })

    return {
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      introText: cckContentPropsMapping.itemIntroText(data?.fields?.introText),
      editorialTeasers,
      salesTeasers,
      instructionalSteps,
      quoteTiles,
      boldTeasers,
    }
  },

  sectionBoldTeaser: (
    data: ContentfulSectionBoldTeaserData,
  ): BoldTeaserProps => {
    return {
      image: cckContentPropsMapping.itemImage(data?.fields?.image),
      preHeadline: data?.fields?.preHeadline,
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      copyText: cckContentPropsMapping.itemCopyText(data?.fields?.copyText),
      button: cckContentPropsMapping.itemButton(data?.fields?.button),
      variant: data?.fields?.variant,
      imagePosition: data?.fields?.imagePosition,
      bottomAsterisk: data?.fields?.bottomAsterisk,
      imageType: data?.fields?.imageType,
      garageSearchDefaultService: data?.fields?.garageSearchDefaultService,
      garageSearchDefaultLocation: data?.fields?.garageSearchDefaultLocation,
    }
  },

  sectionLogoBand: (data: ContentfulSectionLogoBandData): LogoBandProps => {
    return {
      logoImages:
        data?.fields?.logoImages?.map((logoImage) =>
          cckContentPropsMapping.itemImage(logoImage),
        ) ?? [],
      backgroundColor: data?.fields?.backgroundColor || "default",
    }
  },

  meta: (data: ContentfulMetaData): MetaProps => {
    return {
      ...data?.fields,
      ...(data?.fields?.canonicalUrl
        ? {
            canonicalPath: data?.fields?.canonicalUrl,
          }
        : {}),
    }
  },

  itemClickableBanner: (data: ClickableBannerData): ClickableBannerProps => {
    return {
      ...data?.fields,
      icon: data?.fields?.icon?.fields?.iconName || null,
      modalContent: cckContentPropsMapping.itemCopyText(
        data?.fields?.description,
      ),
    }
  },

  productPage: (data: ContentfulPageProductPage): ProductPageProps => {
    return {
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      description: cckContentPropsMapping.itemCopyText(
        data?.fields?.description,
      ),
      ...(data?.fields?.meta
        ? {
            meta: cckContentPropsMapping.meta(data?.fields?.meta),
          }
        : {}),
      image: cckContentPropsMapping.itemFullFormatImage(data?.fields?.image),
      details: cckContentPropsMapping.itemCopyText(data?.fields?.details),
      ...(data?.fields?.configuratorContent
        ? {
            configuratorContent: cckContentPropsMapping.itemCopyText(
              data?.fields?.configuratorContent,
            ),
          }
        : {}),
      ...(data?.fields?.productBanner
        ? {
            productBanner: cckContentPropsMapping.itemClickableBanner(
              data?.fields?.productBanner,
            ),
          }
        : {}),
      ...(data?.fields?.footer
        ? {
            footer: cckContentPropsMapping.sectionFooter(data?.fields?.footer),
          }
        : {}),
    }
  },

  sectionServiceCategories: (
    data: ContentfulSectionServiceCategoriesData,
  ): ServiceCategoriesProps => {
    return {
      ...data?.fields,
    }
  },

  sectionNavigation: (
    data: ContentfulSectionNavigationData,
  ): NavigationProps => {
    return {
      name: data?.fields?.name || "",
      variant: data?.fields?.variant || "default",
      link: data?.fields?.link ?? "",
      icon: data?.fields?.icon?.fields?.iconName || null,
      navigationItems:
        data?.fields?.navigationItems?.map((item) => ({
          name: item?.fields?.name || "",
          icon: item?.fields?.icon?.fields?.iconName || null,
          link: item?.fields?.link || "",
        })) || [],
    }
  },

  sectionFooter: (data: ContentfulSectionFooterData): FooterProps | null => {
    if (!data?.fields?.topNavigation && !data?.fields?.bottomNavigation) {
      return null
    }

    return {
      supportingText: data?.fields?.supportingText || null,
      topNavigation:
        data?.fields?.topNavigation?.map((section) =>
          cckContentPropsMapping.sectionNavigation(section),
        ) || null,
      bottomNavigation:
        data?.fields?.bottomNavigation?.map((section) =>
          cckContentPropsMapping.sectionNavigation(section),
        ) || null,
    }
  },

  sectionHeaderNavigation: (
    data: ContentfulSectionHeaderNavigationData,
  ): HeaderNavigationProps[] | null => {
    if (!data?.fields?.navigationItems?.length) {
      return null
    }

    const headerNavigationItems = data?.fields?.navigationItems.map(
      (navigationItem) => {
        return {
          title: navigationItem?.fields.name,
          ...(navigationItem?.fields.mainNavigationLink
            ? {
                mainNavigationItem: cckContentPropsMapping.itemButton(
                  navigationItem?.fields.mainNavigationLink,
                ),
              }
            : {}),
          navigationItems: cckContentPropsMapping.itemHeaderNavigation(
            navigationItem?.fields.navigationItems,
          ),
        }
      },
    )

    return headerNavigationItems
  },

  itemHeaderNavigation: (
    navigationItemData: ContentfulItemHeaderNavigationData[],
  ): NavigationProps[] | null => {
    if (!navigationItemData?.length) {
      return null
    }

    return (
      navigationItemData?.map((section) =>
        cckContentPropsMapping.sectionNavigation(section),
      ) || null
    )
  },

  itemTopBanner: (data: ContentfulItemTopBannerData): TopBannerProps | null => {
    if (!data?.fields?.content) return null

    return {
      content: data?.fields?.content,
    }
  },

  itemProgressBar: (data: ContentfulItemProgressBarData): ProgressBarProps => {
    // We only pass the "end" to reduce the complexity in the Contentful content type
    return {
      end: data?.fields?.currentProgress || 0,
    }
  },

  sectionNumberedSteps: (
    data: ContentfulSectionNumberedStepsData,
  ): NumberedStepsProps => {
    return {
      headline: cckContentPropsMapping.itemHeadline(data?.fields?.headline),
      items: data?.fields.items.map((item) =>
        cckContentPropsMapping.itemCopyText(item),
      ),
    }
  },

  sectionStickyButton: (data: ContentfulSectionStickyFooterData) => {
    if (!data?.fields?.button) return null
    return cckContentPropsMapping.itemButton(data?.fields?.button)
  },
}
