import { FC } from "react"

import { isDevBuild } from "../../utils/envUtils"
import { getContentType } from "src/helpers/contentful/getContentType"
import { ContentfulCommonEntryData } from "src/types/contentful"

export const ContentfulFallback: FC<{ data: ContentfulCommonEntryData }> = ({
  data,
}) => {
  if (!isDevBuild()) {
    return null
  }

  const contentType = getContentType(data)

  return (
    <>
      <strong>
        Content type <code>{contentType}</code> is not yet implemented.
      </strong>

      <pre
        style={{
          fontFamily: "monospace",
          whiteSpace: "pre",
          maxHeight: "20rem",
          overflow: "auto",
        }}
      >
        {JSON.stringify(data, null, 2)}
      </pre>
    </>
  )
}
