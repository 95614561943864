import dynamic from "next/dynamic"
import { ComponentType } from "react"

import { ContentfulCommonContentType } from "src/types/contentful"

export const componentMap: {
  [key in ContentfulCommonContentType]?: ComponentType<any>
} = {
  // Core
  itemContentSection: dynamic(() =>
    import("../sections/content-section").then(
      (m) => m.ContentfulSectionContentSection,
    ),
  ),
  itemContentStage: dynamic(() =>
    import("../sections/content-stage").then(
      (m) => m.ContentfulSectionContentStage,
    ),
  ),
  sectionAccordion: dynamic(() =>
    import("../sections/accordion").then((m) => m.ContentfulSectionAccordion),
  ),
  sectionCategorySelection: dynamic(() =>
    import("../sections/category-selection").then(
      (m) => m.ContentfulSectionCategorySelection,
    ),
  ),
  sectionHeading: dynamic(() =>
    import("../sections/heading").then((m) => m.ContentfulSectionHeading),
  ),
  sectionMission: dynamic(() =>
    import("../sections/content-mission").then(
      (m) => m.ContentfulContentMission,
    ),
  ),
  sectionRichtext: dynamic(() =>
    import("../sections/richtext").then((m) => m.ContentfulSectionRichtext),
  ),
  sectionServiceFeature: dynamic(() =>
    import("../sections/service-features").then(
      (m) => m.ContentfulSectionServiceFeatures,
    ),
  ),
  sectionSteps: dynamic(() =>
    import("../sections/steps").then((m) => m.ContentfulSectionSteps),
  ),
  sectionStepsCarousel: dynamic(() =>
    import("../sections/steps-carousel").then(
      (m) => m.ContentfulSectionStepsCarousel,
    ),
  ),
  sectionTeaserContent: dynamic(() =>
    import("../sections/teaser").then((m) => m.ContentfulSectionTeaser),
  ),
  sectionTestimonial: dynamic(() =>
    import("../sections/content-testimonials").then(
      (m) => m.ContentfulContentTestimonials,
    ),
  ),
  sectionVideo: dynamic(() =>
    import("../sections/video").then((m) => m.ContentfulSectionVideo),
  ),

  // CCK
  itemButton: dynamic(() =>
    import("../../cck/elements/contentfulButton").then(
      (m) => m.ContentfulButton,
    ),
  ),
  itemCopyText: dynamic(() =>
    import("../../cck/elements/copyText").then((m) => m.CopyText),
  ),
  itemEditorialTeaser: dynamic(() =>
    import("../../cck/elements/editorialTeaser").then((m) => m.EditorialTeaser),
  ),
  itemFoldingArea: dynamic(() =>
    import("../../cck/elements/foldingArea").then((m) => m.FoldingArea),
  ),
  itemFullFormatImage: dynamic(() =>
    import("../../cck/elements/fullFormatImage").then((m) => m.FullFormatImage),
  ),
  itemHeadline: dynamic(() =>
    import("../../cck/elements/headline").then((m) => m.Headline),
  ),
  itemImageTextModule: dynamic(() =>
    import("../../cck/elements/imageTextModule").then((m) => m.ImageTextModule),
  ),
  itemIntroText: dynamic(() =>
    import("../../cck/elements/introText").then((m) => m.IntroText),
  ),
  itemSeparatorLine: dynamic(() =>
    import("../../cck/elements/separatorLine").then((m) => m.SeparatorLine),
  ),
  itemNote: dynamic(() =>
    import("../../cck/elements/note").then((m) => m.Note),
  ),
  itemClickableBanner: dynamic(() =>
    import("../../cck/elements/clickableBanner").then((m) => m.ClickableBanner),
  ),
  itemGarageNetworkComparison: dynamic(() =>
    import("../../cck/elements/garageNetworkComparison").then(
      (m) => m.GarageNetworkComparison,
    ),
  ),
  sectionBenefitsBox: dynamic(() =>
    import("../../cck/sections/benefitBox").then((m) => m.BenefitBox),
  ),
  sectionBoldTeaser: dynamic(() =>
    import("../../cck/sections/boldTeaser").then((m) => m.BoldTeaser),
  ),
  sectionFaqAccordion: dynamic(() =>
    import("../../cck/elements/faqAccordion").then((m) => m.FAQAccordion),
  ),
  sectionHeroTeaser: dynamic(() =>
    import("../../cck/sections/heroTeaser").then((m) => m.HeroTeaser),
  ),
  sectionIconList: dynamic(() =>
    import("../../cck/sections/iconList").then((m) => m.IconList),
  ),
  sectionLogoBand: dynamic(() =>
    import("../../cck/sections/logoBand").then((m) => m.LogoBand),
  ),
  sectionModule: dynamic(() =>
    import("../../cck/sections/sectionModule").then((m) => m.SectionModule),
  ),
  sectionPageIntro: dynamic(() =>
    import("../../cck/sections/pageIntro").then((m) => m.PageIntro),
  ),
  sectionSliderModule: dynamic(() =>
    import("../../cck/sections/sliderModule").then((m) => m.SliderModule),
  ),
  sectionTeaser: dynamic(() =>
    import("../../cck/sections/teaserModule").then((m) => m.TeaserModule),
  ),
  sectionVideoModule: dynamic(() =>
    import("../../cck/sections/videoModule").then((m) => m.VideoModule),
  ),
  sectionServiceCategories: dynamic(() =>
    import("../../cck/sections/serviceCategories").then(
      (m) => m.ServiceCategories,
    ),
  ),
  itemProgressBar: dynamic(() =>
    import("../../cck/elements/progressBar").then((m) => m.ProgressBar),
  ),
  sectionNumberedSteps: dynamic(() =>
    import("../../cck/sections/numberedSteps").then((m) => m.NumberedSteps),
  ),
}
