import {
  ContentfulCommonContentType,
  ContentfulCommonEntryData,
} from "src/types/contentful"

import { cckContentPropsMapping } from "./cckContentPropsMapping"

export const getContentProps = (
  contentType: ContentfulCommonContentType,
  data: ContentfulCommonEntryData,
) => cckContentPropsMapping[contentType]?.(data) || { data }
