import { FC, HTMLAttributes } from "react"

import { create } from "src/helpers/bem"

import styles from "./Image.module.scss"

const bem = create(styles, "Image")

export const maxScreenSize = 1440

export type ImageSource = {
  maxScreenSize: number
  srcSet: string[]
  src: string
  width?: number
  height?: number
}

export type ImageProps = HTMLAttributes<HTMLPictureElement> & {
  alt: string
  sources: ImageSource[]
  className?: string
  loading?: "eager" | "lazy"
  title?: string
}

export const Image: FC<ImageProps> = ({
  alt,
  sources,
  className,
  loading = "lazy",
  title,
  ...attributes
}) => {
  const [fallback] = sources

  if (!fallback) {
    return null
  }

  return (
    <picture className={bem(undefined, undefined, className)} {...attributes}>
      {sources.map((source, index) => (
        <source
          key={index}
          srcSet={source.srcSet.join(", ")}
          media={
            index < sources.length - 1
              ? `(max-width: ${source.maxScreenSize}px)`
              : undefined
          }
        />
      ))}
      <img
        className={bem("display")}
        src={fallback.src}
        alt={alt}
        title={title}
        loading={loading}
      />
    </picture>
  )
}
