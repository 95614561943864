import { FC } from "react"

import { getContentType } from "src/helpers/contentful/getContentType"
import { ContentfulCommonEntryData } from "src/types/contentful"

import { ContentfulFallback } from "./ContentfulFallback"
import { componentMap } from "./helpers/componentMap"
import { getContentProps } from "./helpers/getContentProps"

export const ContentfulSection: FC<{ data?: ContentfulCommonEntryData }> = ({ data }) => {
  if (!data) {
    return null
  }

  const contentType = getContentType(data)

  if (contentType === "sectionFooter" || contentType === "sectionStickyButton") return // footer is always displayed at the bottom

  const Component = componentMap[contentType] || ContentfulFallback
  const props = getContentProps(contentType, data)

  return <Component {...props} />
}
