import {
  ContentfulCommonContentType,
  ContentfulCommonEntryData,
} from "src/types/contentful"

export function getContentType(
  data?: ContentfulCommonEntryData,
): ContentfulCommonContentType {
  return data?.sys?.contentType?.sys?.id as ContentfulCommonContentType
}
